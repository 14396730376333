body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
}
table {
  font-family: "Lato", sans-serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-text-security: disc;
  -moz-text-security: disc;
  appearance: none;
  margin: 0;
}

element.style {
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-radius: 3px !important;
  background: #4a6ba1 !important;
  color: white;
  font-weight: bold;
  height: 30px;
}

.p-link:hover {
  color: white !important;
}
.p-paginator .p-dropdown {
  height: unset !important;
}
#stick .p-datatable.p-datatable-gridlines .p-paginator-bottom {
  padding: 0px !important;
  position: fixed;
  bottom: 10px;
  right: 0;
  width: 100%;
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: lightgray;
}
